<template>
  <div class="container-fluid">
    <div class="card card-container">
      <div>
        <h3 class="text-center">Portal do parceiro</h3>
      </div>
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            * {{ message }}
          </div>
        </div>

        <div class="form-group">
          <label for="username">
            <font-awesome-icon class="icon" icon="envelope"></font-awesome-icon>
            Email
          </label>
          <input v-model="user.username" v-validate="'required'" type="text" :class="[
            'form-control',
            errors.has('username') ? 'required-field' : '',
          ]" name="username" />
        </div>
        <div class="form-group">
          <label for="password">
            <font-awesome-icon class="icon" icon="key"></font-awesome-icon>
            Senha
          </label>
          <input v-model="user.password" v-validate="'required'" type="password" :class="[
            'form-control',
            errors.has('password') ? 'required-field' : '',
          ]" name="password" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span class="mx-1">Login</span>
          </button>
        </div>
        <div style="display: flex; flex-direction: row-reverse" class="form-group">
          <a href="#">
            <!-- Recuperar senha -->
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
import emitter from "@/services/emitter";
// import AuthService from "./../services/auth.service"

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.$router.history.current.fullPath == "/login") {
      let background = document.getElementById("fundo");
      background.style.backgroundImage = "url('/interface-sistema-TJ.png')";
    }

    emitter.on("returnLogin", (bool) => {
      this.loading = bool;
    });

  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            (resp) => {
              if (resp.accessToken) {
                this.$router.push("/home");
              }
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
h3,
label {
  color: #1f2024 !important;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.required-field {
  border: 2px solid rgb(250, 58, 58);
  box-shadow: 0px 0px 10px 1px rgb(233, 136, 136);
}

.container-fluid {
  margin-left: 20vw;
  margin-top: 20vh;
}

.icon {
  width: 1.5vw;
  height: 1.5vh;
}

.card-container.card {
  max-width: 25vw !important;
  max-height: 75vh !important;
}

.card {
  background-color: #fbfbfb;
  padding: 2%;
  margin: 0 auto;
  -moz-border-radius: 3%;
  -webkit-border-radius: 3%;
  border-radius: 3%;
  -moz-box-shadow: 0px 2px 2px #ccc;
  -webkit-box-shadow: 0px 2px 2px #ccc;
  box-shadow: 1px 1px 10px #ccc;
  transition: 0.5s;
}

.card:hover {
  -moz-box-shadow: 0px 2px 2px #778fd1;
  -webkit-box-shadow: 0px 2px 2px #778fd1;
  box-shadow: 1px 1px 10px #778fd1;
}
</style>
